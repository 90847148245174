import { axios } from "../../../lib/axios";

const schedulePayload = ({ planningHorizon, serviceCategory, practId, name }) => {
    return {
        actorId: practId,
        actor: [
            {
                "reference": `Practitioner/${practId}`,
                "display": name,
                "type": "Practitioner"
            }
        ],
        planningHorizon,
        comment: "",
        serviceCategory,
    }
}

export const getSchedules = (actorId, orgId, startDate, endDate) => {
  return axios.get(`schedule/check?actorId=${actorId}&orgId=${orgId}&date=${startDate}&endDate=${endDate}`)
}

export const createSchedule = (payload) => {
    return axios.post(`schedule`, schedulePayload(payload))
}

export const updateSchedule = ({ scheduleId, planningHorizon, serviceCategory, practId, name }) => {
    return axios.put(`schedule/${scheduleId}`, schedulePayload({ planningHorizon, serviceCategory, practId, name }))
}

export const getScheduleSlots = (scheduleId, status=null) => {
    if(status){
        return axios.get(`slot?scheduleId=${scheduleId}&status=${status}`)
    }
    return axios.get(`slot?scheduleId=${scheduleId}`)
}

export const getSlotById = (slotId) => {
    return axios.get(`slot?id=${slotId}`)
}

export const createScheduleSlots = ({ start, end, scheduleId, status = null, serviceCategory=null}) => {
    const payload = {
        "schedule": {
            "reference": `Schedule/${scheduleId}`
        },
        "status": (status) ? status : "busy-unavailable",
        start,
        end,
        "comment": "",
        serviceCategory
    }
    return axios.post(`slot`, payload)
}
export const updateScheduleSlot = ({ start, end, slotId, status = null, serviceCategory=null, orgId }) => {
    const payload = {
        id: slotId,
        start,
        end,
        "status": (status) ? status : "busy-unavailable",
        serviceCategory
    }
    return axios.put(`slot` + ((orgId) ? `?orgId=${orgId}`: ''), payload)
}
export const deleteScheduleSlots = (slotId) => {
    return axios.delete(`slot/${slotId}`)
}