import React, { useEffect, useState } from 'react'
import { FullPageSpinner } from '../common/Spinner/FullPageSpinner'
import { useGetOrgLicensedProducts } from '../../hooks/ReactQueryHooks/useGetOrgLicensedProducts';
import { Button } from '../common/Button';
import { AddNote } from './AddNote';
import AddNoteIcon from '../../assets/images/add-note.png';
import CallIcon from "../../assets/images/call-border.png"
import labIcon from '../../assets/images/chemistry.png';
import medicalIcon from '../../assets/images/medical-prescription.png';
import diagnosticIcon from '../../assets/images/analytics.png';
import referralIcon from '../../assets/images/referral.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { failed, success } from '../common/Toastify';
import { useSelector } from 'react-redux';
import { getOrganization } from '../api/Organization';
import SelectFiled from '../common/textfield/SelectFiled';
import { QuestionnaireFormikObj } from './constant';
import { isAnswered } from '../../utils/questionnaireSupport';
import { useFormik } from 'formik';
import {
  createQuestionnaireResponse,
  editQuestionnaireResponse,
  getSavedQuestionnaireResponses,
  getVideoVisitQuestionnaire
} from '../api/Questionnaire';
import "./PhoneCallCreate.css"
import { editQueueItem, endWaitingRoomMeeting } from '../apps/api/WaitingRoom';
import { axios } from '../../lib/axios';
import moment from 'moment';
import {
  editEncounter,
  getEncounterRecord,
  getQueueRecord,
  updateAppointmentRequest,
  getEncounterQuestionnaireResponses
} from '../apps/api/AppointmentRequests';
import { getSingleUser } from '../api/Individual';
import QuestionnaireRenderer from '../Admin/Questionnaire/QuestionnaireRenderer'
import { useEncounterQuestionnaireResponseQuery } from '../../hooks/ReactQueryHooks/useQuestionnaireResponseQuery';
import ScheduleAppointmentWR from '../apps/Queue/ScheduleAppointmentWR';
import EncounterDetail from "../apps/Patient/EncounterDetail";


function PhoneCallCreate() {
  const { state } = useLocation();
  const {
    encounterId,
    currentUser,
    practitionerName,
    appointmentData,
    practitionerId,
    patientId,
    patientName,
    proceedWith,
    requestedPeriod,
    period,
    questionnairInfo,
    previousStatus
  } = state;

  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
  const orgId = adminOrgs?.[0]?.id;
  const user = useSelector((state) => state?.auth?.user);
  const userRoles = user?.["cognito:groups"];
  const admin = userRoles?.includes('Super-Admin');
  const permissions = user?.permissions;
  const practUserName = user?.name?.[0]?.text;

  const [encounterDetailsShow, setEncouterDetailsShow] = useState(false);
  const [encounterFinished, setEncounterFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [orgnization, setOrganizations] = useState({});
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [textEditorKey, setTextEditorKey] = useState(0);

  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');
  const [savedQuestionnaireResponses, setSavedQuestionnaireResponses] = useState([]);
  const [savedQuestionResponseId, setSavedQuestionResponseId] = useState('');

  // NEW: Whether the *current user* can edit the *selected questionnaire*
  const [canEditSelectedQuestionnaire, setCanEditSelectedQuestionnaire] = useState(true);

  const [practionerData, setPractionerData] = useState({});
  const [myUserId, setMyUserId] = useState("");
  const [encounterRecord, setEncounterRecord] = useState({});
  const [queueRecord, setQueueRecord] = useState({});

  const { data: orgLicensedProducts } = useGetOrgLicensedProducts({});
  const isVirtualCare = admin ? false : orgLicensedProducts?.virtualCare;

  const navigate = useNavigate();
  const [scheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);

  const formik = useFormik({
    ...QuestionnaireFormikObj,
    onSubmit: (values, { setErrors, setFieldValue }) => {
      return new Promise((resolve, reject) => {
        if (!values.questionResponse || values.questionResponse.length === 0) {
          failed("Save questionnaire before proceeding");
          return;
        }
        for (let i = 0; i < values.questionResponse.length; i++) {
          const item = values.questionResponse[i];
          if (item.required && !isAnswered(item)) {
            setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
            reject(`Question : ${item.question} is mandatory.`);
            return;
          }
        }
        setBtnLoading(true);
        setIsDisabled(false);

        // We store all local QRs in localStorage
        let questionResponseIdObj = JSON.parse(localStorage.getItem("questionResponseId")) || {};
        let prevQuestionnaireResponse = JSON.parse(localStorage.getItem("questionnaireResponse")) || {};
        let QRid = values.questionResponseId || questionResponseIdObj?.[selectedQuestionnaire];

        const requestBody = {
          patientID: patientId,
          encounterId: encounterId,
          questionResponse: values.questionResponse,
          appointmentId: "",
          questionnaire: selectedQuestionnaire,
          authorName: user?.name?.[0]?.text,
          authorType: "Practitioner",
          authorId: user["custom:practitioner_id"]
        };

        if (prevQuestionnaireResponse?.[selectedQuestionnaire]) {
          // Update
          editQuestionnaireResponse(QRid, requestBody)
            .then((res) => {
              setFieldValue("questionResponseId", res.data.id);
              prevQuestionnaireResponse[selectedQuestionnaire] = values.questionResponse;
              questionResponseIdObj[selectedQuestionnaire] = QRid;

              localStorage.setItem("questionnaireResponse", JSON.stringify(prevQuestionnaireResponse));
              localStorage.setItem("questionResponseId", JSON.stringify(questionResponseIdObj));

              setSavedQuestionnaireResponses(getEncounterQuestionnaireResponses({ encounterId }));
              success(res.message);
              resolve(res);
            })
            .catch((error) => {
              failed(
                error?.response?.data?.message ||
                error?.response?.data?.error ||
                error.message
              );
              reject(error);
            })
            .finally(() => setBtnLoading(false));

        } else {
          // Create
          createQuestionnaireResponse(requestBody)
            .then((res) => {
              setFieldValue("questionResponseId", res.data.id);
              prevQuestionnaireResponse[selectedQuestionnaire] = values.questionResponse;
              questionResponseIdObj[selectedQuestionnaire] = res.data.id;

              localStorage.setItem("questionnaireResponse", JSON.stringify(prevQuestionnaireResponse));
              localStorage.setItem("questionResponseId", JSON.stringify(questionResponseIdObj));

              setSavedQuestionnaireResponses(getEncounterQuestionnaireResponses({ encounterId }));
              success(res.message);
              resolve(res);
            })
            .catch((error) => {
              failed(
                error?.response?.data?.message ||
                error?.response?.data?.error ||
                error.message
              );
              reject(error);
            })
            .finally(() => setBtnLoading(false));
        }
      });
    }
  });

  // Fetch current user�s Practitioner info
  useEffect(() => {
    const apiBody = {
      id: user?.["custom:unique_id"],
      type: "Practitioner",
      userOrgId: adminOrgs?.[0]?.id
    };
    getSingleUser(apiBody)
      .then((res) => {
        setPractionerData(res?.data);
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
      })
      .catch((err) => {
        console.log("error in getSingleUser:", err)
      });
    return () => {
      localStorage.removeItem("questionResponseId");
      localStorage.removeItem("questionnaireResponse");
    };
  }, []);

  // Encounter record + queue record
  useEffect(() => {
    getEncounterRecord({ encounterId })
      .then((res) => {
        setEncounterRecord(res?.data);
        if (res?.data?.id) {
          getQueueRecord({ encounterId, userOrganizationId: orgId })
            .then((res) => {
              if (res?.data?.id) setQueueRecord(res?.data);
            });
        }
      });
  }, [encounterId, orgId]);

  // For Add Lab, Diagnostics, etc.
  const handleServiceRequest = (requestType) => {
    window.open(
      `/app/encounter-service-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&patientName=${patientName}&patientId=${patientId}&requestType=${requestType}&fromPatient=false`,
      '_blank'
    );
  };
  const handleMedicationRequest = () => {
    window.open(
      `/app/encounter-medication-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&practitionerId=${practitionerId}&patientName=${patientName}&patientId=${patientId}&fromPatient=false`,
      '_blank'
    );
  };

  // Converts the questionnaire definition to a fresh local question array
  const setEmptyQuestionnaire = (qId) => {
    const found = questionnaireList.find((item) => item.id === qId);
    if (!found) return [];
    return found.item.map((item, index) => ({
      id: item.linkId,
      question: item.text,
      key: { index },
      questionType: item.answerType,
      answerType: item.answerType,
      answerOption: item.answerOption?.map((x) => ({
        value: x.value ?? x.text,
        name: x.text ?? x.value,
        text: x.text,
        option: x.value ?? x.text
      })),
      answer: item.answerOption?.map((ans) => ({
        answer: "",
        id: ans?.id,
        option: ans?.value ?? ans?.text
      })),
      required: item?.required,
    }));
  };

  // Converts an existing DB-saved response to a local array
  const setPreviousQuestionnaire = (qResponse) => {
    return qResponse?.item?.map((questionItem, index) => ({
      id: questionItem.linkId,
      question: questionItem.text,
      key: { index },
      questionType: questionItem.questionType,
      answerType: questionItem.questionType,
      answerOption: questionItem?.answer?.map((x) => ({
        value: x?.option,
        name: x?.option,
        text: x?.option,
        option: x?.option
      })),
      answer: questionItem?.answer?.map((ans) => ({
        answer: ans?.valueString || ans?.ratingText || ans?.valueBoolean || "",
        id: questionItem?.linkId,
        option: ans?.option || ans?.valueString
      })),
      required: questionItem?.required
    }));
  };

  // Check if current user can edit the existing questionnaire response
  const checkCanEditAuthor = (responseObj) => {
    if (!responseObj?.author?.reference) return true;
    const authorRef = responseObj.author.reference; // e.g. "Practitioner/12345..."
    const authorId = authorRef.split("/")[1];
    // Return true if the same as current user, false if not
    return authorId === user["custom:practitioner_id"];
  };

  // When user selects from the "Select Questionnaire" dropdown
  const handleSelectChange = async (event) => {
    const selectedQId = event.target.value;
    let questionResponse = [];
    let canEdit = true; // default to true, refine below if a different author is found

    try {
      if (previousStatus === "follow-up") {
        // brand new blank if it was a "follow-up"
        questionResponse = setEmptyQuestionnaire(selectedQId);
        state.previousStatus = null;
        localStorage.removeItem("questionnaireResponse");
      } else {
        // Attempt to find the latest DB-saved questionnaireResponse for that Q
        let latestResponse = null;
        if (savedQuestionnaireResponses && savedQuestionnaireResponses.data) {
          const filteredData = savedQuestionnaireResponses.data
            .filter((r) => r.questionnaire === selectedQId)
            .sort((a, b) => b.authored - a.authored); // newest first
          latestResponse = filteredData.length > 0 ? filteredData[0] : null;
        }

        let localStoredQR = JSON.parse(localStorage.getItem("questionnaireResponse")) || {};

        if (localStoredQR[selectedQId]) {
          // user is editing a local (not-yet-saved) copy
          questionResponse = localStoredQR[selectedQId];
          // If we also have a "latestResponse", check that author
          if (latestResponse) {
            canEdit = checkCanEditAuthor(latestResponse);
            // If not the same author, force a new blank
            if (!canEdit) {
              questionResponse = setEmptyQuestionnaire(selectedQId);
            }
          }
        } else if (latestResponse?.item?.length > 0) {
          // We have an existing response in DB for that Q
          canEdit = checkCanEditAuthor(latestResponse);

          if (!canEdit) {
            // Different author => new blank
            questionResponse = setEmptyQuestionnaire(selectedQId);
          } else {
            // Same author => load the existing data
            questionResponse = setPreviousQuestionnaire(latestResponse);
            formik.setFieldValue("questionResponseId", latestResponse.id);

            let prevQR = {};
            let qridMap = {};
            prevQR[selectedQId] = questionResponse;
            qridMap[selectedQId] = latestResponse.id;
            localStorage.setItem("questionnaireResponse", JSON.stringify(prevQR));
            localStorage.setItem("questionResponseId", JSON.stringify(qridMap));
          }
        } else {
          // There's no existing response => brand new
          questionResponse = setEmptyQuestionnaire(selectedQId);
        }
      }
    } catch (error) {
      console.error("Error retrieving questionnaire response:", error);
    }

    // Update formik + local state
    setSelectedQuestionnaire(selectedQId);
    formik.setFieldValue("questionResponse", questionResponse);
    setTextEditorKey((prevKey) => prevKey + 1);
    localStorage.setItem("selectedQuestionnaireId", selectedQId);

    // Finally, set or reset "canEdit" for the newly selected Q
    setCanEditSelectedQuestionnaire(true);
  };

  const handleEndSession = async () => {
    // Enforce "Save" if required
    if (questionnaireList?.length > 0) {
      let questionResponseIdObj = JSON.parse(localStorage.getItem("questionResponseId")) || {};
      let QRid = formik?.values?.questionResponseId || questionResponseIdObj?.[selectedQuestionnaire];
      if (!QRid && (!formik.values?.questionResponse || formik.values.questionResponse.length === 0)) {
        failed("Save questionnaire before proceeding");
        return;
      }
      // Check required fields
      for (let i = 0; i < (formik.values?.questionResponse || []).length; i++) {
        const item = formik.values.questionResponse[i];
        if (item.required && !isAnswered(item)) {
          formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
          return;
        }
      }
    } else {
      if (!formik.values?.questionResponse || formik.values.questionResponse.length === 0) {
        failed("Save questionnaire before proceeding");
        return;
      }
    }
    resetQuestionnaire();
    if (encounterFinished) {
      const url = `/app/encounter-details?encounterId=${state?.encounterId}`;
      navigate(url, { replace: true });
      return;
    }
    setIsLoading(true);
    setLoadingText("Ending Session");

    localStorage.removeItem("userJoined");
    if (state?.isWaitingRoom) {
      endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId })
        .then((res) => success(res.message))
        .catch((err) => console.log(err));
    }
    const appintmentTime = [{
      start: moment(requestedPeriod?.[0]?.start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
    }];

    const encounterData = await axios.get(`encounter/${state?.encounterId}`);
    const encounterPeriod = encounterData?.data?.period;

    if (appointmentData || appointmentData?.id) {
      updateAppointmentRequest({
        encounterId: state?.encounterId,
        id: state?.appointmentData?.id,
        status: "completed",
        patientId,
        practitionerId,
        sessionToken: ""
      })
        .then(async (res) => {
          if (res.status === true) {
            const actor = {
              display: practionerData.name[0].text,
              reference: `Practitioner/${myUserId}`,
              type: "Practitioner"
            };
            return axios.put(`encounter/${state?.encounterId}`, {
              status: encounterData?.data?.status,
              actor,
              proceedWith,
              requestedPeriod: appintmentTime,
              intakeQuestions: questionnairInfo,
              class: encounterData?.data?.class,
              period: { start: encounterPeriod?.start, end: null }
            }).then(() => {
              setIsLoading(false);
              setLoadingText(false);
              if (proceedWith !== "WAITING_ROOM") {
                setEncounterFinished(true);
              }
              const url = `/app/encounter-details?encounterId=${state?.encounterId}`;
              navigate(url, { replace: true });
            })
              .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
              .finally(() => setIsLoading(false));
          }
        })
        .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message));
    } else {
      try {
        const actor = {
          display: practionerData.name[0].text,
          reference: `Practitioner/${myUserId}`,
          type: "Practitioner"
        };
        await axios.put(`encounter/${encounterId}`, {
          status: encounterData?.data?.status,
          proceedWith,
          requestedPeriod: appintmentTime,
          intakeQuestions: questionnairInfo,
          actor,
          class: encounterData?.data?.class,
          period: {
            start: encounterPeriod?.start,
            end: null,
          },
        });
        setIsLoading(false);
        setLoadingText(false);
        setEncounterFinished(true);
        const url = `/app/encounter-details?encounterId=${state?.encounterId}`;
        navigate(url, { replace: true });
      } catch (err) {
        console.error('Error:', err);
        setIsLoading(false);
      }
    }
  };

  // Load the organization data
  useEffect(() => {
    getOrganization(adminOrgs?.[0]?.id)
      .then((res) => setOrganizations(res.data))
      .catch((res) => {
        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
      });
  }, [adminOrgs]);

  // On mount, fetch org's assigned questionnaires + existing QRs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgRes = await getOrganization(adminOrgs?.[0]?.id);
        const assigned = orgRes?.data?.assignedQuestionnaire || {};
        const videoVisitIds = assigned["video-visit"] || [];

        if ("video-visit" in assigned && videoVisitIds.length > 0) {
          const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds), orgId);
          const modifiedData = VVRes?.data?.map((item) => ({
            ...item,
            name: item.title,
            value: item.id
          }));
          setQuestionnaireList(modifiedData);
        }

        const responseData = await getEncounterQuestionnaireResponses({ encounterId });
        setSavedQuestionnaireResponses(responseData);

        // If there's a stored selected questionnaire ID, re-select it
        const storedId = localStorage.getItem('selectedQuestionnaireId');
        if (storedId) {
          // If we had an in-progress state
          if (previousStatus?.startsWith("in-progress")) {
            setSelectedQuestionnaire(storedId);

            let filteredData = [];
            if (responseData && responseData.data) {
              filteredData = responseData.data
                .filter((record) => record.questionnaire === storedId)
                .sort((a, b) => b.authored - a.authored);
            }
            const latest = filteredData.length > 0 ? filteredData[0] : null;
            let questionResponse = [];

            if (latest?.item?.length > 0) {
              const canEdit = checkCanEditAuthor(latest);

              if (!canEdit) {
                // If not the same author => blank out
                questionResponse = setEmptyQuestionnaire(storedId);
              } else {
                questionResponse = setPreviousQuestionnaire(latest);

                formik.setFieldValue("questionResponseId", latest.id);

                let prevQuestionnaireResponse = {};
                let questionResponseIdObj = {};
                prevQuestionnaireResponse[storedId] = questionResponse;
                questionResponseIdObj[storedId] = latest.id;
                localStorage.setItem("questionnaireResponse", JSON.stringify(prevQuestionnaireResponse));
                localStorage.setItem("questionResponseId", JSON.stringify(questionResponseIdObj));
              }
            } else {
              // brand new blank
              questionResponse = setEmptyQuestionnaire(storedId);
            }

            setCanEditSelectedQuestionnaire(true);
            formik.setFieldValue("questionResponse", questionResponse);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [adminOrgs, encounterId, previousStatus, orgId]);

  const resetQuestionnaire = () => {
    formik.resetForm();
    setSelectedQuestionnaire('');
  };

  const findQrId = (id) => {
    let questionResponseIdObj = JSON.parse(localStorage.getItem("questionResponseId"));
    return questionResponseIdObj?.[id] || null;
  };

  const handleView = () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/app/patients-details?patientId=${patientId}&patientName=${patientName}`;
    window.open(url, "_blank");
  };

  // PARK
  const handleParkCallClick = () => {
    if (questionnaireList?.length > 0) {
      let questionResponseIdObj = JSON.parse(localStorage.getItem("questionResponseId")) || {};
      let QRid = formik?.values?.questionResponseId || questionResponseIdObj?.[selectedQuestionnaire];
      if (!QRid && (!formik.values?.questionResponse || formik.values.questionResponse.length === 0)) {
        failed("Save questionnaire before proceeding");
        return;
      }
      for (let i = 0; i < (formik.values?.questionResponse || []).length; i++) {
        const item = formik.values.questionResponse[i];
        if (item.required && !isAnswered(item)) {
          formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
          return;
        }
      }
    } else {
      if (!formik.values?.questionResponse || formik.values.questionResponse.length === 0) {
        failed("Save questionnaire before proceeding");
        return;
      }
    }
    formik.handleSubmit();

    setIsLoading(true);
    setLoadingText("Loading...");

    const utcString = new Date().toISOString().split(".")[0] + ".00Z";

    if (state?.appointmentData) {
      const requestedPeriod = [{
        start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      }];
      return updateAppointmentRequest({
        encounterId: state?.encounterId,
        id: state?.appointmentData?.id,
        status: "completed",
        patientId,
        practitionerId,
        sessionToken: ""
      })
        .then(async (res) => {
          if (res?.status === true) {
            const actor = {
              display: practionerData.name[0].text,
              reference: `Practitioner/${myUserId}`,
            };
            let questionnareResponses = appointmentData?.intakeQuestions;
            let newResponseAdded = questionnareResponses ? {
              ...questionnareResponses,
              intakeCommunicationChannel: "phone",
              intakeReason: appointmentData?.intakeQuestions?.reason,
              intakeAssignedPractitioner: {
                individual: {
                  display: practionerData?.name[0]?.text,
                  reference: `Practitioner/${myUserId}`,
                  type: "Practitioner"
                },
                period: { start: utcString, end: null }
              }
            } : {};

            await axios.put(`encounter/${state?.encounterId}`, {
              status: "parked",
              actor,
              requestedPeriod,
              class: "NULL",
              intakeQuestions: newResponseAdded
            });
            success("Appointment successfully parked");
            navigate("/app/dashboard");
          }
        })
        .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
        .finally(() => setIsLoading(false));

    } else {
      // Non-appointment scenario
      const currentActor = {
        reference: `Practitioner/${myUserId}`,
        display: practUserName,
        type: "Practitioner",
      };
      let encounterIntakeQuestions = {
        ...encounterRecord?.intakeQuestions,
        intakeCommunicationChannel: "phone",
        intakeAssignedPractitioner: {
          individual: {
            display: practionerData?.name[0]?.text,
            reference: `Practitioner/${myUserId}`,
            type: "Practitioner"
          },
          period: { start: utcString, end: null }
        }
      };
      let encounterPayload = {
        patient: encounterRecord?.subject,
        orgId: encounterRecord?.orgId,
        questionnairInfo: encounterIntakeQuestions,
        requestedPeriod: encounterRecord?.requestedPeriod,
        practitionerId: encounterRecord?.practitionerId,
        practitionerName: practUserName,
        proceedWith: encounterRecord?.proceedWith,
        status: "parked",
        encounterId: encounterRecord?.id,
        patientLocation: encounterRecord?.patientLocation,
        participant: encounterRecord?.participant,
        actor: currentActor,
        encounterClass: "NULL"
      };

      let oldPractHistory = queueRecord?.practitionerHistory;
      if (oldPractHistory) {
        let parkingPractitioner = {
          actor: {
            display: practionerData?.name[0]?.text,
            reference: `Practitioner/${myUserId}`,
            type: "Practitioner"
          },
          assignedBy: {
            actor: {
              display: practionerData?.name[0]?.text,
              reference: `Practitioner/${myUserId}`,
              type: "Practitioner"
            },
          },
          updatedAt: Date.now()
        };
        const updatedPractitionerHistory = [...oldPractHistory, parkingPractitioner];
        editQueueItem({ id: queueRecord?.id, practitionerHistory: updatedPractitionerHistory }, orgId)
          .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res?.message);
          });
      }

      editEncounter(encounterPayload)
        .then((res) => {
          if (res?.status === true) {
            success("Encounter Parked Successfully");
            navigate("/app/dashboard");
          }
        })
        .catch((res) => {
          failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  // FOLLOW-UP
  const handleFollowUpClick = () => {
    if (questionnaireList?.length > 0) {
      let questionResponseIdObj = JSON.parse(localStorage.getItem("questionResponseId")) || {};
      let QRid = formik?.values?.questionResponseId || questionResponseIdObj?.[selectedQuestionnaire];
      if (!QRid && (!formik.values?.questionResponse || formik.values.questionResponse.length === 0)) {
        failed("Save questionnaire before proceeding");
        return;
      }
      for (let i = 0; i < (formik.values?.questionResponse || []).length; i++) {
        const item = formik.values.questionResponse[i];
        if (item.required && !isAnswered(item)) {
          formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
          return;
        }
      }
    } else {
      if (!formik.values?.questionResponse || formik.values.questionResponse.length === 0) {
        failed("Save questionnaire before proceeding");
        return;
      }
    }

    formik.handleSubmit();
    setIsLoading(true);
    setLoadingText("Loading...");

    if (state?.appointmentData) {
      const requestedPeriod = [{
        start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      }];
      return updateAppointmentRequest({
        encounterId: state?.encounterId,
        id: state?.appointmentData?.id,
        status: "completed",
        patientId,
        practitionerId,
        sessionToken: ""
      })
        .then(async (res) => {
          if (res?.status === true) {
            const actor = {
              display: practionerData.name[0].text,
              reference: `Practitioner/${myUserId}`,
            };
            let questionnareResponses = appointmentData?.intakeQuestions;
            let newResponseAdded = questionnareResponses ? {
              ...questionnareResponses,
              intakeCommunicationChannel: "phone",
              intakeReason: appointmentData?.intakeQuestions?.reason
            } : {};
            await axios.put(`encounter/${state?.encounterId}`, {
              status: "follow-up",
              actor,
              requestedPeriod,
              class: "NULL",
              intakeQuestions: newResponseAdded
            });
            localStorage.removeItem("questionResponseId");
            localStorage.removeItem("questionnaireResponse");
            success("Appointment added to Follow-Up");
            navigate("/app/dashboard");
          }
        })
        .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
        .finally(() => setIsLoading(false));

    } else {
      const currentActor = {
        reference: `Practitioner/${myUserId}`,
        display: practUserName,
        type: "Practitioner",
      };
      let encounterIntakeQuestions = {
        ...encounterRecord?.intakeQuestions,
        intakeCommunicationChannel: "phone"
      };
      let encounterPayload = {
        patient: encounterRecord?.subject,
        orgId: encounterRecord?.orgId,
        questionnairInfo: encounterIntakeQuestions,
        requestedPeriod: encounterRecord?.requestedPeriod,
        practitionerId: encounterRecord?.practitionerId,
        practitionerName: practUserName,
        proceedWith: encounterRecord?.proceedWith,
        status: "follow-up",
        encounterId: encounterRecord?.id,
        patientLocation: encounterRecord?.patientLocation,
        participant: encounterRecord?.participant,
        actor: currentActor,
        encounterClass: "NULL"
      };
      setIsLoading(true);
      editEncounter(encounterPayload)
        .then((res) => {
          if (res?.status === true) {
            localStorage.removeItem("questionResponseId");
            localStorage.removeItem("questionnaireResponse");
            success("Encounter Added to Follow-Up");
            navigate("/app/dashboard");
          }
        })
        .catch((res) => {
          failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleScheduleAppointmentClick = () => {
    setScheduleAppointmentModalOpen(true);
  };

  return (
    encounterDetailsShow ? (
      <EncounterDetail
        setEncouterDetailsShow={setEncouterDetailsShow}
        EncounterId={state?.encounterId}
        PatientId={patientId}
        PractionerData={practionerData}
        PractitionerId={myUserId}
        ShowCompleteButton={true}
      />
    ) : (
      <div>
        <section className="common-listing">
          {isLoading && <FullPageSpinner loadingText={loadingText} />}
          {scheduleAppointmentModalOpen && (
            <ScheduleAppointmentWR
              onShow={scheduleAppointmentModalOpen}
              onHide={() => setScheduleAppointmentModalOpen(false)}
              encounterId={encounterId}
              questionnaireData={questionnairInfo}
              patientName={patientName}
              patientId={patientId}
              questionnaireResponseId={savedQuestionResponseId}
            />
          )}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="head-wrap">
              <h1>
                <span className="big-head" style={{ fontSize: "24px", textTransform: "capitalize" }}>
                  {currentUser?.patient?.display}
                </span>
              </h1>
              <div style={{ marginLeft: "10px" }}>
                <img src={CallIcon} alt="call" />
                <span>{questionnairInfo?.callerNumber}</span>
                <span>
                  {questionnairInfo?.callerNumberExt && questionnairInfo?.callerNumberExt !== ""
                    ? "ext. " + questionnairInfo?.callerNumberExt
                    : ""}
                </span>
              </div>
            </div>
            <div className="heading-wrap mb-3">
              {!state?.isWaitingRoom && (
                <div className="right-wrap">
                  <Button
                    className="custom-btn"
                    title="Open Dashboard in a new tab"
                    variant="primary"
                    onClick={() => handleView()}
                  >
                    Patient Dashboard
                  </Button>
                  <Button
                    className="custom-btn"
                    title="Schedule Appointment"
                    variant="primary"
                    onClick={() => handleScheduleAppointmentClick()}
                  >
                    Schedule Appointment
                  </Button>
                  <Button
                    className="custom-btn ms-2"
                    title="Add Note"
                    variant="primary"
                    style={{ padding: "0" }}
                    onClick={() => setAddNoteModal(true)}
                  >
                    +<img src={AddNoteIcon} alt="Add Note" />
                  </Button>
                  <AddNote
                    show={addNoteModal}
                    encounterId={state?.encounterId}
                    patientName={currentUser?.patient?.display}
                    onHide={() => setAddNoteModal(false)}
                  />

                  {isVirtualCare && permissions.includes("Create Prescriptions") && (
                    <Button
                      className="custom-btn ms-2"
                      title="Create Prescription"
                      variant="primary"
                      style={{ padding: "0" }}
                      onClick={() =>
                        orgnization?.sft?.enabled &&
                          (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)
                          ? handleMedicationRequest()
                          : orgnization?.sft?.enabled &&
                            !orgnization?.sft?.individualDelivery &&
                            !orgnization?.sft?.locationDelivery
                            ? failed("Please enable SFT delivery options.")
                            : failed("Please enable SFT to send document.")
                      }
                    >
                      <img src={medicalIcon} alt="Prescription" />
                    </Button>
                  )}

                  {isVirtualCare && permissions.includes("Create Lab Requisitions") && (
                    <Button
                      className="custom-btn ms-2"
                      title="Add Lab service request"
                      variant="primary"
                      style={{ padding: "0" }}
                      onClick={() =>
                        orgnization?.sft?.enabled &&
                          (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)
                          ? handleServiceRequest("Lab")
                          : orgnization?.sft?.enabled &&
                            !orgnization?.sft?.individualDelivery &&
                            !orgnization?.sft?.locationDelivery
                            ? failed("Please enable SFT delivery options.")
                            : failed("Please enable SFT to send document.")
                      }
                    >
                      <img src={labIcon} alt="Lab" />
                    </Button>
                  )}

                  {isVirtualCare && permissions.includes("Create Diagnostic Imaging Requests") && (
                    <Button
                      className="custom-btn ms-2"
                      title="Add Diagnostic service request"
                      variant="primary"
                      style={{ padding: "0" }}
                      onClick={() =>
                        orgnization?.sft?.enabled &&
                          (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)
                          ? handleServiceRequest("Diagnostic")
                          : orgnization?.sft?.enabled &&
                            !orgnization?.sft?.individualDelivery &&
                            !orgnization?.sft?.locationDelivery
                            ? failed("Please enable SFT delivery options.")
                            : failed("Please enable SFT to send document.")
                      }
                    >
                      <img src={diagnosticIcon} alt="Diagnostic" />
                    </Button>
                  )}

                  {isVirtualCare && permissions.includes("Create Service Referrals") && (
                    <Button
                      className="custom-btn ms-2"
                      title="Add Referral service request"
                      variant="primary"
                      style={{ padding: "0" }}
                      onClick={() =>
                        orgnization?.sft?.enabled &&
                          (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)
                          ? handleServiceRequest("Referral")
                          : orgnization?.sft?.enabled &&
                            !orgnization?.sft?.individualDelivery &&
                            !orgnization?.sft?.locationDelivery
                            ? failed("Please enable SFT delivery options.")
                            : failed("Please enable SFT to send document.")
                      }
                    >
                      <img src={referralIcon} alt="Referral" />
                    </Button>
                  )}

                  <Button
                    className="custom-btn ms-2"
                    title="Proceed"
                    variant="primary"
                    onClick={handleEndSession}
                  >
                    Proceed
                  </Button>
                </div>
              )}
            </div>
          </div>

          {encounterRecord?.intakeQuestions?.callerRelationship !== "Self" && (
            <div
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
                gap: "20px"
              }}
            >
              <div>
                <span style={{ fontWeight: "bold" }}>Caller Name: </span>
                <span>{encounterRecord?.intakeQuestions?.callerName}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Caller Relationship: </span>
                <span>{encounterRecord?.intakeQuestions?.callerRelationship}</span>
              </div>
            </div>
          )}

          <div className="questionnair-wrapper">
            <div className="heading-wrap h-change" style={{ display: 'flex', alignItems: 'center' }}>
              <h1 className="big-head">Questionnaire</h1>
            </div>
            <hr />
            <div className="chat-sidebar">
              <div className="individual-chart-wrap">
                <div
                  className="chat-body common-form"
                  style={{ paddingTop: "1rem", justifyContent: "center", alignItems: "center" }}
                >
                  {/* Dropdown is always active so user can switch questionnaires */}
                  <SelectFiled
                    keyField="questionnaire"
                    label="Select Questionnaire"
                    formik={formik}
                    onChange={handleSelectChange}
                    value={selectedQuestionnaire}
                    options={questionnaireList}
                  />
                  <hr />

                  {/* Pass a readOnly flag if user is not the original author */}
                  <QuestionnaireRenderer
                    key={textEditorKey}
                    formik={formik}
                    questionResponse={formik.values.questionResponse}
                    readOnly={!canEditSelectedQuestionnaire}
                  />
                </div>

                <div className="msg-footer">
                  <div
                    className="btn-wrap"
                    style={{ display: 'flex', justifyContent: "end", gap: "1rem" }}
                  >
                    <Button type="button" onClick={handleParkCallClick}>
                      Park Call
                    </Button>
                    <Button type="button" onClick={handleFollowUpClick}>
                      Add to Follow-Up
                    </Button>

                    {/* Only show Save/Update if user can edit this questionnaire */}
                    {canEditSelectedQuestionnaire && (
                      <Button
                        type="submit"
                        isLoading={btnLoading}
                        onClick={formik.handleSubmit}
                        style={{
                          background: 'linear-gradient(90deg, #4CAF50 0%, #388E3C 100%)'
                        }}
                      >
                        {findQrId(selectedQuestionnaire) ? "Update" : "Save"}
                      </Button>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
}

export default PhoneCallCreate;
