import moment from "moment";
import * as Yup from "yup";

export const conditionStatusOptions = [
    {value: "active", name: "Active"},
    {value: "inactive", name: "Inactive"},
    {value: "remission", name: "Remission"},
    {value: "resolved", name: "Resolved"},
    {value: "recurrence", name: "Recurrence"},
    {value: "relapse", name: "Relapse"}
]

export const familyHistoryOptions = [
    {value: "MTH", name: "Mother"},
    {value: "FTH", name: "Father"},
    {value: "BRO", name: "Brother"},
    {value: "SIS", name: "Sister"},
    {value: "GRMTH", name: "Grandmother"},
    {value: "GRFTH", name: "Grandfather"},
    {value: "AUNT", name: "Aunt"},
    {value: "UNCLE", name: "Uncle"},
    {value: "SON", name: "Son"},
    {value: "DAU", name: "Daughter"},
    {value: "EXT", name: "Other"}
]

export const observationNameOptions = [
    {value: "Pregnancies", name: "Pregnancies"},
    {value: "Personal/Social", name: "Personal/Social"},
]

export const FormikObjectCondition = {
    initialValues: {
        conditionName: "",
        diagnosisDate: moment().format("YYYY-MM-DD"),
        status:"",
        note:""
    },
    validationSchema: Yup.object({
        conditionName: Yup.string().max(50, "Maximum length is 50 characters.").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        // status: Yup.string().required("Required status field"),
        diagnosisDate: Yup.string().required("Required Date field"),
        note: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
    }),
}

export const FormikObjectSurgeryScreening = {
    initialValues: {
        procedureName: "",
        performedDateTime: moment().format("YYYY-MM-DD"),
        note:""
    },
    validationSchema: Yup.object({
        procedureName: Yup.string().max(50, "Maximum length is 50 characters.").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        performedDateTime: Yup.string().required("Required Date field"),
        note: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
    }),
}

export const FormikObjectFamilyHistory = {
  initialValues: {
    conditionName: "",
    // date: moment().format("YYYY-MM-DD"),
    relationship: "",
    relation: "",
    relationshipCode: "",
    ageValue: "",
    note: "",
  },
  validationSchema: Yup.object({
    conditionName: Yup.string()
      .max(50, "Maximum length is 50 characters.")
      .required("Required family history field")
      .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    // relation: Yup.string().required("Required relationship field"),
    relationship: Yup.string().when("relation", (relation, schema) =>
      relation === "EXT"
        ? Yup.string()
            .max(25, "Maximum length is 25 characters.")
            .required("Required Other field")
        : Yup.string().notRequired()
    ),
    ageValue: Yup.string()
      .required("Required Age Of Onset field.")
      .test("is-three-digits", "Age must be a 1-3 digit number.", (value) =>
       /^\d{0,3}$/.test(value)
      ).test("max-age", "Maximum allowed age is 120.", (value) => {
        const numericValue = parseInt(value, 10);
        return numericValue <= 120;
     }),
    note: Yup.string()
      .max(250, "Maximum length is 250 characters.")
      .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
  }),
};

export const FormikObjectObservaton = {
    initialValues: {
        observationName: "",
        note:""
    },
    validationSchema: Yup.object({
        observationName: Yup.string().max(50, "Maximum length is 50 characters.").required("Required Observation field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        note: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
    }),
}

export const FormikObjectNote = {
    initialValues: {
        note:""
    },
    validationSchema: Yup.object({
        note: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').required("Note field is required")
    }),
}