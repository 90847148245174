import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { Button } from "../../common/Button";
import { FormikObj } from "./Constants";
import DateSelector from "../../common/textfield/DateSelector";
import moment from "moment";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import TimeSelector from "../../common/textfield/TimeSelector";
import { editPatientMedication } from "../api/Patient/index";
import { failed, success } from "../../common/Toastify";
import { decryptData } from "../../EncryptDecrypt";
import { dosageSet, dosagePhrased, texts as dosageTexts, dosageToInterval } from "../../../utils/DoseSupport";

function EditMedicationSchedule({ patientId, modalShow, handleShow, refreshMed }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [repeatsTime, setRepeatsTime] = useState({});
    const [editable, setEditable] = useState(true)

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {            
            if (!handleTimeError()) {
                setBtnLoading(true);
                const numberOfRepeats = dosageToInterval(formik.values.interval);
                const repeatsTimeTemp = Object.values(repeatsTime)
                    .slice(0, numberOfRepeats)
                    .map(({ time }) => ({ time: moment(time).format("HH:mm") }));

                editPatientMedication({ ...values, id: modalShow?.id, medId: modalShow?.medId, patientId, repeatsTime: repeatsTimeTemp })
                    .then((res) => {
                        if (res?.status === true) {
                            success(res.message);
                            handleShow();
                            refreshMed()
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    const handleRepeatsTimeChange = (val, keyNo) => {
        setRepeatsTime({ ...repeatsTime, [keyNo]: { ...repeatsTime?.[keyNo], time: val, error: false } })
    }

    const handleTimeError = () => {
        let temp = { ...repeatsTime }
        Array.from(Array(parseInt(formik.values.repeats)).keys()).forEach(key => {
            temp = { ...temp, [key + 1]: { ...temp?.[key + 1], error: temp?.[key + 1]?.time ? false : "Please provide Time" } };
        })
        setRepeatsTime(temp)
        return Object.values(temp).some((e) => e.error)
    }

    const handleFormChange = (e) => {
        if (e.target.id === "repeats") {
            const repeatsValue = parseInt(formik.values.repeats, 10);
            if (!isNaN(repeatsValue)) {
            setRepeatsTime(
                Object.keys(repeatsTime).filter((key) => Array(repeatsValue).fill(0).map((e, i) => i + 1).includes(parseInt(key))).reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: repeatsTime[key] || {}
                    });
                }, {})
            )
        }
    }
}

    useEffect(() => {
        if (modalShow?.medId) {
            if(!modalShow?.effectivePeriod){
                setEditable(false)
            }
            let startDate = modalShow?.effectivePeriod?.start?.split("T")?.[0];
            startDate = startDate >= moment() ? startDate : moment().format("YYYY-MM-DD")
            startDate = moment(startDate)
            formik.setFieldValue("start", startDate || "");
            formik.setFieldValue("end", moment(modalShow?.effectivePeriod?.end?.split("T")?.[0]) || "");
            formik.setFieldValue("name", decryptData(modalShow?.name) || "");
            formik.setFieldValue("dose", modalShow?.dose || "");
            formik.setFieldValue("reason", decryptData(modalShow?.reason) || "");
            formik.setFieldValue("repeats", modalShow?.repeats.length || "");
            formik.setFieldValue("interval", modalShow?.interval || "");
            var obj = modalShow?.repeats.reduce((acc, cur, i) => {
                acc[i + 1] = { time: moment(moment().format("YYYY-MM-DD") + ' ' + cur.time) };
                return acc;
            }, {});
            setRepeatsTime(obj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow])
    
    

    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {!editable ? "View" : "Edit"} medication schedule
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                    onChange={handleFormChange}
                >
                    {editable && <Row>
                        <Col>
                            <DateSelector
                                keyField={"start"}
                                label={"Start Date"}
                                formik={formik}
                                minDate={moment()}
                                maxDate={""}
                                hasValue={formik.values.start}
                                placeholder={"Start Date"}
                                readOnly={!editable || 
                                    moment(moment()).isAfter(formik.values.start, "day")} // check if today's date is greater than the value
                            />
                        </Col>
                        <Col>
                            <DateSelector
                                keyField={"end"}
                                label={"End Date"}
                                formik={formik}
                                minDate={moment(formik.values.start)}
                                maxDate={""}
                                hasValue={formik.values.end}
                                readOnly={!editable ||
                                    moment(moment()).isAfter(formik.values.end, "day")} // check if today's date is greater than the value
                                placeholder={"End Date"}
                            />
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <TextInput
                                keyField={"name"}
                                label={"Medication Name"}
                                formik={formik}
                                placeholder={"Medication Name"}
                                readOnly={!editable}
                            />
                        </Col>
                        <Col>
                            <TextInput
                                keyField={"dose"}
                                label={"Dose"}
                                formik={formik}
                                placeholder={"Dose"}
                                readOnly={!editable}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea
                                keyField={"reason"}
                                label={"Reason for Use"}
                                formik={formik}
                                placeholder={"Reason, why the patient should take this medication..."}
                                readOnly={!editable}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectFiled
                                keyField={"interval"}
                                label={"Frequency"}
                                formik={formik}
                                options={dosageSet('standard').map((dosage) => 
                                ({ "name": dosagePhrased(dosage), "value": dosage })
                                )}
                                readOnly={!editable}
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
                    {formik.values.repeats ? Array.from(Array(dosageToInterval(formik.values.interval)).keys()).map((keyNo) => {
                            return (
                                <Col key={keyNo}>
                                    <TimeSelector
                                        label={`${dosageTexts('dosage')} ${keyNo + 1}`}
                                        value={repeatsTime?.[keyNo + 1]?.time}
                                        onChange={(val) => handleRepeatsTimeChange(val, keyNo + 1)}
                                        error={repeatsTime?.[keyNo + 1]?.error}
                                        readOnly={!editable}
                                    />
                                </Col>
                            )
                        }) : null}   
                    </Row>

                    <div className="btn-wrap">
                        <Button onClick={() => { handleShow() }} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        {editable && (
                            <Button type="submit" isLoading={btnLoading}>
                                Update
                            </Button>
                        )}
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
    }

export default EditMedicationSchedule;