import React, { useEffect, useState } from 'react'
import { Button } from '../common/Button'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore, } from '@mui/icons-material'
import TextEditor from '../common/textfield/TextEditor'
import { QuestionnaireFormikObj } from './constant'
import { isAnswered } from '../../utils/questionnaireSupport';
import { useFormik } from 'formik'
import SelectFiled from '../common/textfield/SelectFiled'
import Fields from '../Admin/Dashboard/Fields'; 
import { createQuestionnaireResponse, editQuestionnaireResponse } from "../api/Questionnaire";
import { failed, success } from "../common/Toastify";
import { useQuestionnaireQuery } from '../../hooks/ReactQueryHooks/useQuestionnaireQuery';
import { useLocation } from 'react-router-dom';
import { getOrganization } from '../api/Organization';
import { useSelector } from "react-redux";
import moment from "moment";
import { getVideoVisitQuestionnaire } from '../api/Questionnaire'
import QuestionnaireRenderer from '../Admin/Questionnaire/QuestionnaireRenderer'

const QuestionnaireBigBox = () => {
    const [btnLoading, setBtnLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const [getCareVisitId, setGetCareVisitId] = useState([]);
    const orgId = adminOrgs?.[0]?.id;
    const [textEditorKey, setTextEditorKey] = useState(0);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(questionnaireList.length && questionnaireList[0]?.id || '');
    const user = useSelector((state) => state?.auth?.user);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getOrganization(adminOrgs?.[0]?.id);
                const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
                const videoVisitIds = assignedQuestionnaire["video-visit"] || [];
                if('video-visit' in assignedQuestionnaire && videoVisitIds.length > 0) {
                    const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds), orgId)
                    const modifiedData = VVRes?.data?.map(item => ({
                        ...item,
                        name: item.title,
                        value: item.id
                    }));
                    setQuestionnaireList(modifiedData);
                }
                
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 1000);
        return () => clearInterval(intervalId);
    }, [adminOrgs?.[0]?.id]); 

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedData = searchParams.get('data');
    const valuesArray = receivedData.split(',');
    const encounterId = valuesArray[0];
    const patientId = valuesArray[1];
    const appointmentId = valuesArray[2];
    const slotStartTime = valuesArray[3]/* .slice(11, 16) */;
    const slotEndTime = valuesArray[4]/* .slice(11, 16) */;
    const patientName = valuesArray[5];

    const findQrId = (id) => {
        let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId"))
        questionResponseId = questionResponseId?.[id] || null
        if (questionResponseId){
            return questionResponseId
        }
        return false;
    }

    function toCamelCase(text) {
        return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    const patientNameInCamelCase = toCamelCase(patientName);

    const formik = useFormik({
        ...QuestionnaireFormikObj,
        onSubmit: (values, { setErrors, setFieldValue }) => {        
            if (values?.questionResponse.length === 0 || values.questionResponse === undefined ) {
                failed("Complete questionnaire before proceeding");
                return;
            }
            for (let i = 0; i < values?.questionResponse.length; i++) {
                const item = values?.questionResponse[i];
                if (item.required && !isAnswered(item)) {
                    setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                    return;
                }
            }
            setBtnLoading(true);
            setIsDisabled(false);
            let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId")) || {};
            let prevQuestionnaireResponse =JSON.parse(localStorage.getItem("questionnaireResponse")) || {}
            let QRid = values.questionResponseId  || questionResponseId?.[selectedQuestionnaire];
            if(prevQuestionnaireResponse?.[selectedQuestionnaire]){
                editQuestionnaireResponse(QRid, { patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId , questionnaire: selectedQuestionnaire, authorName: user?.name[0]?.text, authorType: "Practitioner", authorId: user["custom:practitioner_id"]})
                    .then((res) => {
                        setFieldValue("questionResponseId", res.data.id)
                        prevQuestionnaireResponse[selectedQuestionnaire] = values?.questionResponse
                        questionResponseId[selectedQuestionnaire] = QRid
                        localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                        localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))
                        success(res.message);
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
            }else{
                createQuestionnaireResponse({ patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId, questionnaire: selectedQuestionnaire, authorName: user?.name[0]?.text, authorType: "Practitioner", authorId: user["custom:practitioner_id"] })
                .then((res) => {
                    setFieldValue("questionResponseId", res.data.id)
                    prevQuestionnaireResponse[selectedQuestionnaire] = values?.questionResponse
                    questionResponseId[selectedQuestionnaire] = res.data.id
                    localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                    localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))
                    success(res.message);
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
            }
        },
    });

    useEffect(() => {
        const questionResponse = questionnaireList.filter(item => item?.id === formik?.values?.questionnaire)?.[0]?.item.map(item => ({
            id: item?.linkId,
            question: item?.text,
            questionType: item?.answerType,
            answer: item.answerOption?.map(item => ({ answer: "", id: item?.id })),
            required: item?.required,
        }));
        formik.setFieldValue("questionResponse", questionResponse)
    }, [formik.values?.questionnaire])

    // const handleSelectChange = (event) => {
    //     const selectedQuestionnaireId = event.target.value;
    //     const questionResponse = questionnaireList
    //         .filter(item => item?.id === selectedQuestionnaireId)?.[0]?.item
    //         .map(item => ({
    //             id: item?.linkId,
    //             question: item?.text,
    //             questionType: item?.answerType,
    //             answer: item.answerOption?.map(item => ({ answer: "", id: item?.id })),
    //             required: item?.required,
    //         })) || [];

    //     formik.setFieldValue("questionResponse", questionResponse);       
    //     setTextEditorKey(prevKey => prevKey + 1);
    // };

    const setEmptyQuestionnaire = (qId) => {
        return questionnaireList
            .filter(item => item?.id === qId)?.[0]?.item
            .map((item , index) => ({
                id: item?.linkId,
                question: item?.text,
                key:{index},
                questionType: item?.answerType,
                answerType: item?.answerType,
                answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
                answer: item.answerOption?.map(item => ({ answer: "", id: item?.id, option: (item?.value) ? item.value : item?.text })),
                required: item?.required,
            }))
    }

    const handleSelectChange = (event) => {
        const selectedQuestionnaireId = event.target.value;        
        let questionResponse =[];
        let questionnaireResponse =JSON.parse(localStorage.getItem("questionnaireResponse"))
        if(questionnaireResponse){
            questionResponse = questionnaireResponse
        }else{
            questionResponse = setEmptyQuestionnaire(selectedQuestionnaireId)
        }        
        formik.setFieldValue("questionResponse", questionResponse);
        // formik.setFieldValue("questionResponseId", "")
        setSelectedQuestionnaire(selectedQuestionnaireId);
        setTextEditorKey(prevKey => prevKey + 1);
    };

    const handleCancelButton = () => {
        let questionResponse = setEmptyQuestionnaire(selectedQuestionnaire);
        formik.setFieldValue("questionResponse", questionResponse);
    }

    return (
        <>
            <section className="common-listing">
                <div className="heading-wrap h-change" style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ flex: 1 }}>Questionnaire</h1>
                    <h1 style={{ flex: 1, textAlign: 'center' }}>Patient Name: <span style={{ color: '#8e8e8e' }}>{patientNameInCamelCase}</span></h1>
                    <h1 style={{ flex: 1, textAlign: 'right' }}>Slot Booked: <span style={{ color: '#8e8e8e' }}>{`${moment(slotStartTime).format("HH:mm")} - ${moment(slotEndTime).format("HH:mm")}`}</span></h1>
                </div>
                <hr />

                <div className="chat-sidebar" >
                    <div className="individual-chart-wrap">
                        <div className="chat-body common-form" style={{ paddingTop: "1rem", justifyContent: "center", alignItems: "center" }}>
                            <SelectFiled
                                keyField={"questionnaire"}
                                label={"Select Questionnaire"}
                                formik={formik}
                                readOnly={isDisabled}
                                options={questionnaireList}
                                onChange={handleSelectChange}
                                value={selectedQuestionnaire} 
                            />
                            <hr />
                            <QuestionnaireRenderer formik={formik} questionResponse={formik.values.questionResponse} parentIndex={textEditorKey} />
                            {/* {formik.errors.isQuestionsError && (
                                <div className="error-message" style={{ color: "red" }}>{formik.errors.isQuestionsError}</div>
                            )} */}
                        </div>

                        <div className="msg-footer">
                            <div className="btn-wrap" style={{ display: 'flex', justifyContent: "center", gap: "1rem" }}>
                            <Button type="submit" isLoading={btnLoading} onClick={formik.handleSubmit}>{findQrId(selectedQuestionnaire) ? "Update" : "Save"}</Button>
                                <Button onClick={handleCancelButton} variant="secondary" title="Cancel">Clear Form</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default QuestionnaireBigBox