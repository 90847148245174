import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";
import moment from "moment";

export function usePatientEncounterHistoryQuery({ startDate, endDate, patientId, onSuccess, practitionerId, ...props }) {
    // convert to local start time 00:00:00
    let start = new Date(startDate)
    //start = start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
    start = new Date(start);

    // convert to local end time 23:59:59
    let end = new Date(endDate).getTime();
    // end = end.setMinutes(end.getMinutes() - end.getTimezoneOffset());
    end = end + 86399000; // add 23:59 minutes, so it is the end of the end day, in local time

    startDate = new Date(start).toISOString().replace(".000Z", "Z");
    endDate = new Date(end).toISOString().replace(".000Z", "Z");
    const fetch = () => {
        if(practitionerId) return axios.get(`encounter?id=${patientId}&practitionerId=${practitionerId}&startDate=${startDate}&endDate=${endDate}`)
        else return axios.get(`encounter?id=${patientId}&startDate=${startDate}&endDate=${endDate}`)
    }

    const query = useQuery(
        ["patient-medication", startDate, endDate, patientId],
        () => fetch(),
        {
            select: (data) => {
                return data?.data?.filter(item => item.status === 'finished');
            },
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query }
}