import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import EditIcon from "../../../../assets/images/edit.png";
import ViewIcon from "../../../../assets/images/view.png";
import DrugIcon from "../../../../assets/images/drugs.png";
import TakenIcon from "../../../../assets/images/check-mark.png";
import NotTakenIcon from "../../../../assets/images/prohibition-symbol.png";
import ErrorIcon from "../../../../assets/images/error-icon.png";
import { MedicationHeadTitle, } from "../Constants";
import moment from "moment";
import { Skeleton, TableCell } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import { decryptData } from "../../../EncryptDecrypt";
import { DateSelectorWithoutFormik } from "../../../common/textfield/DateSelector";
import { usePatientMedicationQuery } from "../../../../hooks/ReactQueryHooks/usePatientMedicationQuery";

export function MedicationsList({ refreshMed, patientId, setMedEditModal, }) {
    const [MedicationDate, setMedicationDate] = useState(moment().format("YYYY-MM-DD"));
    const [MedicationData, setMedicationData] = useState({});

    const onSuccess = (data) => {
        let formatDataByTime = {};
        Object?.keys(data)?.forEach((medId) => {
            data?.[medId]?.repeats?.forEach(({ time, status }) => {
                const newTime = moment(moment().format("YYYY-MM-DD") + ' ' + time).format("HH:mm");
                if (!formatDataByTime[newTime]) formatDataByTime[newTime] = [{ ...data?.[medId], time, status, medId }];
                else formatDataByTime[newTime] = [...formatDataByTime[newTime], { ...data?.[medId], status, medId }];
            });
        });
        setMedicationData(formatDataByTime);
    };
    const { isLoading, isFetching, refetch } = usePatientMedicationQuery({ onSuccess, patientId: patientId, date: MedicationDate });

    useEffect(() => {
        refreshMed.current = refetch;
    }, [])

    return (
        <>
            <div className="mnth-date-wrap">
                <DateSelectorWithoutFormik
                    label={false}
                    value={moment(MedicationDate)}
                    handleChange={(val) => setMedicationDate(val.format("MM/DD/YYYY"))}
                    type={'readonly'}
                />
            </div>
            <div className="table-wrap medication-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            {MedicationHeadTitle?.map(title => <th key={title} style={{
                                textAlign: title === "Action" ? "center" : "auto"
                            }}>{title}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading || isFetching
                            ? [1, 2, 3, 4, 5, 6].map(val => <tr key={val}>
                                {MedicationHeadTitle?.map(index => <td key={index}>
                                    <Skeleton animation="wave" />
                                </td>)}
                            </tr>)
                            : Object.keys(MedicationData).length > 0 ? Object.keys(MedicationData)?.map(timeGroup => {
                                return MedicationData?.[timeGroup]?.map((med, index) => {
                                    let { name, dose, status, time, effectivePeriod } = med;
                                    name = decryptData(name);
                                    return <tr key={`${timeGroup}-${index}`}>
                                        {time
                                            ? <td rowSpan={MedicationData?.[timeGroup].length} style={{
                                                whiteSpace: "nowrap",
                                                paddingLeft: "20px"
                                            }}>
                                                {timeGroup}
                                                {MedicationData?.[timeGroup]?.some(() => status?.toLowerCase() === "not-taken") ? <img src={ErrorIcon} className="error-icon" alt={name} /> : null}
                                            </td>
                                            : null}
                                        <td>
                                            <div className="medi-wrap">
                                                <div className="img-wrap">
                                                    <img src={DrugIcon} alt={name} />
                                                </div>
                                                <div className="text-wrap">
                                                    <h4>{name}</h4>
                                                    <p>{dose}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="medi-status">
                                                {status?.toLowerCase() === "taken" ? <div className="left">
                                                    <img src={TakenIcon} alt={name} />
                                                    {status}
                                                </div> : status?.toLowerCase() === "take" ? <div className="left">Not Taken</div> : <div className="left">
                                                    <img src={NotTakenIcon} alt={name} />
                                                    {status.replace("-", " ")}
                                                </div>}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-wrap">
                                                <div className={effectivePeriod ? "success-btn" : "view-btn"} style={{
                                                    marginRight: "0"
                                                }} onClick={() =>{console.log(med);setMedEditModal(med)}} title={effectivePeriod ? "Edit" : "View"}>
                                                    <img src={effectivePeriod ? EditIcon : ViewIcon} alt="Edit" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>;
                                });
                            }) : <tr>
                                <TableCell colSpan={MedicationHeadTitle.length} style={{
                                    textAlign: "center"
                                }}>
                                    No Data Available
                                </TableCell>
                            </tr>}
                    </tbody>
                </Table>
            </div>
        </>
    );
}
