import * as Yup from "yup";

const SUPPORTED_FORMATS = ["application/pdf"];
const FILE_SIZE = 160 * 1024;

export const FormikObj = {
    initialValues: {
        id: "",
        categoryDisplay: "",
        categoryCode : "",
        pages: "",
        status: 'new',
        locationName: "",
        locationId: "",
        document: null,
        selectedPractitionerName : "",
        practitionerId: "",
        patientId: "",
        assignPatient: ""
    },
    validationSchema: Yup.object({
        categoryCode: Yup.string().required("Required Category field"),
        pages: Yup.string().nullable(true).test("less-than-100", "Pages cannot exceed 100", value => value === null || value === undefined || value <= 100).test("is-three-digit", "Page number must not start with zeros.", value => value === null || value === undefined || /^(?!0)\d{1,3}$/.test(String(value))),
        locationId: Yup.string().required("Required Source field"),
        status : Yup.string().required("Required Status field"),
        practitionerId: Yup.string(),
        patientId : Yup.string(),
        document: Yup.mixed().nullable().required()
            // .test("FILE_SIZE", "Uploaded file is too big.", (value) => !value || (value && value.size <= FILE_SIZE))
            // .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
        // isRootLevel: Yup.boolean(),
    }),
}

export const FormikObjEdit = {
    initialValues: {
        id: "",
        categoryDisplay: "",
        categoryCode : "",
        pages: "",
        status: 'new',
        locationName: "",
        locationId: "",
        document: null,
        selectedPractitionerName : "",
        practitionerId: "",
        patientId: "",
        assignPatient: ""
    },
    validationSchema: Yup.object({
        //categoryCode: Yup.string().required("Required Category field"),
        pages: Yup.string().nullable(true).test("less-than-100", "Pages cannot exceed 100", value => value === null || value === undefined || value <= 100).test("is-three-digit", "Page number must not start with zeros.", value => value === null || value === undefined || /^(?!0)\d{1,3}$/.test(String(value))),
        locationId: Yup.string().required("Required Source field"),
        status : Yup.string().required("Required Status field"),
        practitionerId: Yup.string(),
        patientId : Yup.string()
    }),
}

export const filterStatusOptions = [
    { value: "new", name: "New" },
    { value: "assigned", name: "Assigned" },
    { value: "acknowledged", name: "Acknowledged" },
    { value: "completed", name: "Completed" },
    { value: "cancelled", name: "Cancelled" },
]

export const categoryOptions = [
    { value: "result_lab", name: "Lab Result" },
    { value: "result_diagnostic_image", name: "DI Result" },
    { value: "result_referral", name: "Referral Response" },
    { value: "result_unknown", name: "Other" }
];


export const handleExt = (event) => {
    let key = event.key;
    let regex = new RegExp("^[0-9]+$");
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };